/* eslint-disable @next/next/no-css-tags */
import { BASE_URL } from "#next-seo.config";
import { NextSeo } from "next-seo";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import Container from "~/components/Container";
import { TimelineItemJob } from "~/components/TimelineJobItem";
import { useWindowSize } from "~/lib/hooks/useWindowSize";

// seo
export const relativeUrl = "/work/closed-source";
export const url = `${BASE_URL}${relativeUrl}`;
export const title = "Samuel Batista's Closed Source Work";
export const description = "Samuel Batista's professional work history (closed source software).";

function toggleExpand(id: string) {
  return function onClick(event) {
    document.getElementById(id).click();
  };
}

export default function WorkClosedSource({ jobData }) {
  const router = useRouter();
  const { width } = useWindowSize();

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url,
          title,
          description
        }}
      />
      <Head>
        <meta name="description" content="Samuel Batista's work history as a Full Stack Engineer."></meta>
        <link rel="preload" as="style" type="text/css" href="/work/stacks.css" />
        <link rel="preload" as="style" type="text/css" href="/work/primary.css" />
        <link rel="preload" as="style" type="text/css" href="/work/pdf.css" />
      </Head>
      <Container>
        <link rel="stylesheet" type="text/css" href="/work/stacks.css" />
        <link rel="stylesheet" type="text/css" href="/work/primary.css" />
        <link rel="stylesheet" type="text/css" href="/work/pdf.css" />
        <style type="text/css">
          {`
a { text-decoration: underline; }
.description-expander-label { text-decoration: underline; color: #07C }
.keyboard-console { background-color: black; background-color: rgba(0, 0, 0, .8); position: fixed; left: 100px; bottom: 100px;padding: 10px; text-align: left; border-radius: 6px; z-index: 1000 }.keyboard-console pre { background-color: transparent; color: #ccc; width: auto; height: auto; padding: 0; margin: 0; overflow: visible; line-height:1.5; border: none;}.keyboard-console pre b, .keyboard-console pre a { color: white !important; }.keyboard-console pre kbd { display: inline-block; font-family: monospace; }.keyboard-selected { box-shadow: 15px 15px 50px rgba(0, 0, 0, .2) inset; }
.description-content-full p { color: black; }
.block { font-weight: bold; color: black; }

body {
  padding-top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@media only screen and (max-width: 1280px) {
  body {
    min-width: auto;
    width: ${width}px
  }

  main {
    min-width: auto;
    width: ${width}px;
    justify-content: top;
    display: relative;
  }

  .scaleable-wrapper {
    
  }

  .work-page {
  }
}
`}
        </style>
        <div className="scaleable-wrapper">
          <div className="work-page">
            <div className="timeline-wrapper">
              <div className="timeline">
                <header className="header">
                  <div id="form-section-PersonalInfo" className="header-edit-section header-edit-section-1">
                    <div className="display-only">
                      <div className="d-inline-block p12 bg-black-025 bar-lg my24 bs-md">
                        <Image
                          className="d-block bar-sm"
                          src="/images/avatar.webp"
                          alt="avatar"
                          width={136}
                          height={136}
                        />
                      </div>
                      <div className="name">
                        <h4>Samuel Batista</h4>
                      </div>
                      <div className="job has-tooltip">Principal Full Stack Developer</div>

                      <div className="grid jc-center gs16 fc-light fw-wrap">
                        <div className="grid ai-center">
                          <div className="grid--cell mr4">
                            <svg
                              aria-hidden="true"
                              className="svg-icon iconLocation"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                            >
                              <path d="M2 6.38C2 9.91 8.1 17.7 8.1 17.7c.22.29.58.29.8 0 0 0 6.1-7.8 6.1-11.32A6.44 6.44 0 008.5 0 6.44 6.44 0 002 6.38zm9.25.12a2.75 2.75 0 11-5.5 0 2.75 2.75 0 015.5 0z"></path>
                            </svg>
                          </div>
                          <div className="grid--cell wmx2 truncate">
                            <a href="https://www.google.com/maps/place/Baltimore,+MD/" target="__blank">
                              Baltimore, Maryland
                            </a>
                          </div>
                        </div>

                        <div className="grid--cell mr4">
                          <svg
                            aria-hidden="true"
                            className="svg-icon iconLink"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <path d="M7.22 11.83a6 6 0 001.62.85l.61-1.8a4.1 4.1 0 114.04-.8l1.26 1.42a6 6 0 10-7.53.33zm3.43-5.6a6 6 0 00-1.6-.87L8.4 7.15a4.1 4.1 0 11-4.05.73L3.12 6.43a6 6 0 107.53-.2z"></path>
                          </svg>
                        </div>
                        <div className="grid--cell">
                          <a href={BASE_URL}>{BASE_URL}</a>
                        </div>

                        <div className="grid--cell mr4">
                          <svg
                            aria-hidden="true"
                            className="svg-icon iconTwitter"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <path
                              d="M17 4.04c-.59.26-1.22.44-1.88.52a3.3 3.3 0 001.44-1.82c-.64.37-1.34.64-2.09.79a3.28 3.28 0 00-5.6 2.99A9.3 9.3 0 012.12 3.1a3.28 3.28 0 001.02 4.38 3.28 3.28 0 01-1.49-.4v.03a3.29 3.29 0 002.64 3.22 3.34 3.34 0 01-1.48.06 3.29 3.29 0 003.07 2.28 6.58 6.58 0 01-4.85 1.36 9.33 9.33 0 005.04 1.47c6.04 0 9.34-5 9.34-9.33v-.42a6.63 6.63 0 001.63-1.7L17 4.04z"
                              fill="#2AA3EF"
                            ></path>
                          </svg>
                        </div>
                        <div className="grid--cell">
                          <a href="https://twitter.com/gamedevsam" target="__blank">
                            gamedevsam
                          </a>
                        </div>

                        <div className="grid--cell mr4">
                          <svg
                            aria-hidden="true"
                            className="svg-icon iconGitHub"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <path
                              d="M9 1a8 8 0 00-2.53 15.59c.4.07.55-.17.55-.38l-.01-1.49c-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82a7.42 7.42 0 014 0c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48l-.01 2.2c0 .21.15.46.55.38A8.01 8.01 0 009 1z"
                              fill="#010101"
                            ></path>
                          </svg>
                        </div>
                        <div className="grid--cell">
                          <a href="https://github.com/gamedevsam" target="__blank">
                            gamedevsam
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="form-section-PersonalStatementAndTools"
                    className="header-edit-section header-edit-section-2"
                  >
                    <div className="display-only">
                      <div className="description">
                        <div className="description">
                          <span className="description-content-full">
                            <div id="form-section-PrimarySkills" className="header-edit-section header-edit-section-3">
                              <div className="display-only">
                                <div className="user-technologies">
                                  <label className="block">Expert</label>
                                  <div className="timeline-item-tags">
                                    <span className="post-tag">JavaScript</span>
                                    <span className="post-tag">TypeScript</span>
                                    <span className="post-tag">React</span>
                                    <span className="post-tag">C++</span>
                                    <span className="post-tag">C#</span>
                                    <span className="post-tag">Lua</span>
                                    <span className="post-tag">Haxe</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              id="form-section-SecondarySkills"
                              className="header-edit-section header-edit-section-3"
                            >
                              <div className="display-only">
                                <div className="user-technologies">
                                  <label className="block">Intermediate</label>
                                  <div className="timeline-item-tags">
                                    <span className="post-tag">Node.js</span>
                                    <span className="post-tag">Next.js</span>
                                    <span className="post-tag">Vue</span>
                                    <span className="post-tag">Java</span>
                                    <span className="post-tag">Spring</span>
                                    <span className="post-tag">ASP.NET</span>
                                    <span className="post-tag">WordPress</span>
                                    <span className="post-tag">PostgreSQL</span>
                                    <span className="post-tag">AWS</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>

                        {/* <div id="form-section-FavoriteTools" className="header-edit-section header-edit-section-3">
                          <div className="display-only">
                            <div className="user-technologies">
                              <label className="block">Favorite Tools</label>
                              <div className="timeline-item-tags">
                                <span className="post-tag">Visual Studio Code</span>
                                <span className="post-tag">Visual Studio</span>
                                <span className="post-tag">Chrome Debug Tools</span>
                                <span className="post-tag">Git</span>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </header>
                <div className="js-featured-assessments"></div>

                <div className="timeline-feed">
                  <div className="timeline-line"></div>
                  {jobData.map((job, index) => (
                    <TimelineItemJob key={index} {...job} />
                  ))}

                  <div id="item-5736827" className="timeline-item education" data-order-by="20091101-000-0005736827">
                    <header>
                      <span className="timeline-item-type">Education</span>

                      <span className="timeline-item-date">Dec 2007 → Nov 2009 (1 year, 11 months)</span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="img logo">
                        <Image width={106} height={74} src="/work/7SK2Q.webp" alt="BS Game Development" />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          <a href="https://www.fullsail.edu/" rel="nofollow">
                            BS Game Development, Full Sail University
                          </a>
                        </div>

                        <div className="timeline-item-tags grid gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">C++</span>
                          <span className="grid--cell s-tag">C#</span>
                          <span className="grid--cell s-tag">Lua</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description description-ellipsis">
                            <input
                              className="dno description-expander"
                              id="item-5736827-collapsible-description"
                              type="checkbox"
                            />
                            <span className="description-content-full">
                              <p>
                                Full Sail is a great school for people wanting to get into the video game industry. I
                                learned everything from tools and gameplay programming to advanced topics such as ui,
                                animation, sound and graphics.
                              </p>
                            </span>
                            <span className="description-content-truncated" style={{ maxHeight: "65px" }}>
                              <p>
                                Full Sail is a great school for people wanting to get into the video game industry. I
                                learned everything from tools and gameplay programming to advanced topics such as ui,
                                animation, sound and graphics.
                              </p>
                            </span>
                            <label
                              className="description-expander-label"
                              data-less="Less"
                              data-more="Read more"
                              onClick={toggleExpand("item-5736827-collapsible-description")}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export async function getStaticProps() {
  const dataFile = `${process.cwd()}/data/work/closed-source-work-history.json`;
  const data = (await import("fs")).readFileSync(dataFile).toString();
  return { props: { jobData: JSON.parse(data) } };
}
