import Image from "next/image";
import { useState } from "react";

interface TimelineItemJobProps {
  id: string;
  startDate: string;
  endDate: string;
  duration: string;
  position: string;
  company: string;
  companyUrl: string;
  logoSrc: string;
  skills: string[];
  description: string;
}

export const TimelineItemJob: React.FC<TimelineItemJobProps> = ({
  id,
  startDate,
  endDate,
  duration,
  position,
  company,
  companyUrl,
  logoSrc,
  skills,
  description
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpand = (): void => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="timeline-item job" data-order-by={`${startDate.replace(/-/g, "")}-002-00${id}`}>
      <header>
        <span className="timeline-item-type">Position</span>
        <span className="timeline-item-date">
          {startDate} → {endDate}{" "}
          {duration && (
            <>
              {" "}
              <span>({duration})</span>
            </>
          )}
        </span>
      </header>

      <div className="timeline-item-content with-description">
        <div className="js-listcard-container"></div>
        <div className="img logo align-self js-listcard-hover" data-list-url={companyUrl}>
          <Image width={56} height={56} src={logoSrc} alt={company} className="js-list-img" />
        </div>

        <div className="timeline-item-text">
          <div className="timeline-item-title">
            {position} at{" "}
            <span className="js-listcard-hover" data-list-url={companyUrl}>
              <a href={companyUrl}>{company}</a>
            </span>
          </div>
          <div className="timeline-item-tags d-flex gs4 fl-none fw-wrap mt16">
            {skills.map((skill, index) => (
              <span key={index} className="grid--cell s-tag">
                {skill}
              </span>
            ))}
          </div>

          <div className="timeline-item-paragraph">
            <div className="description description-ellipsis">
              <input
                className="dno description-expander"
                id="item-10112971-collapsible-description"
                type="checkbox"
                checked={isExpanded}
                readOnly
              />
              <span className="description-content-full">
                <p>{description}</p>
              </span>
              <span className="description-content-truncated" style={{ maxHeight: "65px" }}>
                <p>{description}</p>
              </span>
              <label className="description-expander-label" onClick={toggleExpand}>
                {isExpanded ? "" : "Read more"}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
